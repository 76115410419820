import { createApp }     from "vue";
import BootstrapVueNext  from "bootstrap-vue-next";
import { Tooltip }       from "bootstrap";
import VueApexCharts     from "vue3-apexcharts";
import Rollbar           from "rollbar";
import sentryLoader      from './js/sentry';
import Vue3EasyDataTable from "vue3-easy-data-table";

import App      from "./App.vue";
import router   from "./router";
import store    from "./state/store";
import { i18n } from "./locales";

import "@/vee-validate";
import "@/js/axios";
import "@/js/utils";
import "@/design/app.scss";

import VueECharts         from "vue-echarts";
import * as echarts       from "echarts/core";
import { PieChart }       from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { TooltipComponent, LegendComponent, LegendPlainComponent, VisualMapComponent, DatasetComponent } from "echarts/components";

echarts.use([PieChart, TooltipComponent, LegendComponent, LegendPlainComponent, CanvasRenderer, VisualMapComponent, DatasetComponent]);


const dashboardApp = createApp(App);

const sentry = sentryLoader(dashboardApp, router)

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
if (process.env.VUE_APP_ROLLBAR_TOKEN) {
  const rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: { environment: process.env.NODE_ENV }
  });

  // If you have already set up a global error handler,
  // just add `vm.$rollbar.error(err)` to the top of it.
  // If not, this simple example will preserve the app’s existing
  // behavior while also reporting uncaught errors to Rollbar.
  dashboardApp.config.errorHandler = (err) => {
    rollbar.error(err);
    sentry.captureException(err);
    throw err; // rethrow
  };

  dashboardApp.config.productionTip = false;
  dashboardApp.config.globalProperties.$rollbar = rollbar;
}

dashboardApp.use(router);
dashboardApp.use(i18n);
dashboardApp.use(BootstrapVueNext);
dashboardApp.use(store);

dashboardApp.component("apexchart",  VueApexCharts);
dashboardApp.component("data-table", Vue3EasyDataTable);
dashboardApp.component("v-chart",    VueECharts);

dashboardApp.directive("b-tooltip", {
  mounted(el) {
    const tooltip = new Tooltip(el, { trigger: "hover", placement: "auto" });
    el.addEventListener("click", () => tooltip.hide());
  }
});

router.isReady().then(() => {
  dashboardApp.mount("#app");
})
